import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Grid,
  Link,
} from "@mui/material";

export default function SubmitButtons({
  submitText = "Submit",
  isLoading,
  disableSubmit = false,
}: {
  submitText?: string;
  isLoading: boolean;
  disableSubmit?: boolean;
}) {
  const failureUrl = sessionStorage.getItem("failureUrl");
  const isFailureUrl = failureUrl && failureUrl !== "undefined";

  return (
    <Box>
      <Grid container spacing={isFailureUrl ? 6 : 0} justifyContent={"center"}>
        {isFailureUrl && (
          <Grid item xs={6}>
            <Link href={failureUrl}>
              <Button variant="outlined" fullWidth>
                Cancel
              </Button>
            </Link>
          </Grid>
        )}
        {!disableSubmit && (
          <Grid item xs={isFailureUrl ? 6 : 12}>
            <LoadingButton
              isLoading={isLoading}
              type="submit"
              variant="contained"
              fullWidth
            >
              {submitText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export function LoadingButton({
  children,
  isLoading,
  ...props
}: ButtonProps & { isLoading: boolean }) {
  return (
    <Button
      translate="no"
      {...props}
      disabled={isLoading || props.disabled || false}
    >
      {isLoading && (
        <CircularProgress color="inherit" size={16} sx={{ mr: 2 }} />
      )}
      {children}
    </Button>
  );
}
