import { Alert, Link } from "@mui/material";

export default function YahooApplicationAlert() {
  return (
    <Alert severity="info" variant="filled">
      Yahoo specific configuration
      <ul>
        <li>
          Sign in to your Yahoo account:{" "}
          <Link
            href="https://login.yahoo.com/myaccount/security/app-password/?src=ym&activity=ybar-acctinfo&pspid=159612001&done=https%3A%2F%2Fmail.yahoo.com%2F%3F.intl%3Dfr%26.lang%3Dfr-FR&scrumb=DZAkPoQ2bql"
            target="_blank"
          >
            Sign in
          </Link>
        </li>
        <li>Follow the instructions and click on "Generate password"</li>
        <li>
          Click on "Copy" and paste the password in the field below. Don't
          forget to finish the Yahoo wizard by clicking on "Finish".
        </li>
      </ul>
    </Alert>
  );
}
