import axios from "axios";

export const getClientIp = async (): Promise<string | null> => {
  try {
    const { data } = await axios.get<{ ip: string }>("https://api.ipify.org", {
      params: { format: "json" },
    });

    return data?.ip ?? null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
